<script>
import ky from "ky";

export default {
  data() {
    return {
      email: "",
      loading: false,
    };
  },
  methods: {
    async submit() {
      if (!this.$refs.field.checkValidity()) {
        this.$refs.field.reportValidity();
        return;
      }

      this.loading = true;
      const json = await ky
        .post("/ajax/subscribe/unisender_subscribe.php", {
          json: { email: this.email },
        })
        .json();
      this.loading = false;

      if (json && json.result && json.result == "success") {
        this.email = "";

        if (json.msg) {
          alert(json.msg);
        }
      } else {
        if (json.error) {
          alert(json.error);
        } else {
          alert(
            "<h1>Ошибка подписки на рассылку</h1><p>Пожалуйста сообщите администратору сайта</p>"
          );
        }
      }
    },
  },
};
</script>

<template>
  <form class="subscribe-form" @submit.prevent="submit">
    <div class="form-title">{{ $s("STR_SUBSCRIPTION_TITLE") }}</div>
    <div class="form-description">{{ $s("STR_SUBSCRIPTION_DESCRIPTION") }}</div>
    <div class="field-wrap">
      <input
        class="field"
        type="email"
        ref="field"
        v-model="email"
        :placeholder="$s('STR_ENTER_YOUR_EMAIL')"
        required
      />
      <button class="button" type="submit" :title="$s('STR_SUBSCRIBE')">
        <icon-arrow-apply></icon-arrow-apply>
      </button>
    </div>
  </form>
</template>
