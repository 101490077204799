<template>
  <svg
    class="svg-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 16.55C13.7333 15.15 15.0417 13.8042 15.925 12.5125C16.8083 11.2208 17.25 10.0167 17.25 8.9C17.25 7.91667 17.0708 7.08333 16.7125 6.4C16.3542 5.71667 15.9125 5.15833 15.3875 4.725C14.8625 4.29167 14.2917 3.97917 13.675 3.7875C13.0583 3.59583 12.5 3.5 12 3.5C11.5 3.5 10.9417 3.59583 10.325 3.7875C9.70833 3.97917 9.1375 4.29167 8.6125 4.725C8.0875 5.15833 7.64583 5.71667 7.2875 6.4C6.92917 7.08333 6.75 7.91667 6.75 8.9C6.75 10.0167 7.19167 11.2208 8.075 12.5125C8.95833 13.8042 10.2667 15.15 12 16.55ZM12 18.175C11.8833 18.175 11.775 18.1625 11.675 18.1375C11.575 18.1125 11.4833 18.0667 11.4 18C9.31667 16.3833 7.77083 14.8292 6.7625 13.3375C5.75417 11.8458 5.25 10.3667 5.25 8.9C5.25 7.76667 5.45417 6.77083 5.8625 5.9125C6.27083 5.05417 6.8 4.33333 7.45 3.75C8.1 3.16667 8.825 2.72917 9.625 2.4375C10.425 2.14583 11.2167 2 12 2C12.7833 2 13.575 2.14583 14.375 2.4375C15.175 2.72917 15.9 3.16667 16.55 3.75C17.2 4.33333 17.7292 5.05417 18.1375 5.9125C18.5458 6.77083 18.75 7.76667 18.75 8.9C18.75 10.3667 18.2458 11.8458 17.2375 13.3375C16.2292 14.8292 14.6833 16.3833 12.6 18C12.5167 18.0667 12.425 18.1125 12.325 18.1375C12.225 18.1625 12.1167 18.175 12 18.175ZM12 10.5C12.4833 10.5 12.8958 10.3292 13.2375 9.9875C13.5792 9.64583 13.75 9.23333 13.75 8.75C13.75 8.26667 13.5792 7.85417 13.2375 7.5125C12.8958 7.17083 12.4833 7 12 7C11.5167 7 11.1042 7.17083 10.7625 7.5125C10.4208 7.85417 10.25 8.26667 10.25 8.75C10.25 9.23333 10.4208 9.64583 10.7625 9.9875C11.1042 10.3292 11.5167 10.5 12 10.5ZM6 22C5.78333 22 5.60417 21.9292 5.4625 21.7875C5.32083 21.6458 5.25 21.4667 5.25 21.25C5.25 21.0333 5.32083 20.8542 5.4625 20.7125C5.60417 20.5708 5.78333 20.5 6 20.5H18C18.2167 20.5 18.3958 20.5708 18.5375 20.7125C18.6792 20.8542 18.75 21.0333 18.75 21.25C18.75 21.4667 18.6792 21.6458 18.5375 21.7875C18.3958 21.9292 18.2167 22 18 22H6Z"
    />
  </svg>
</template>
