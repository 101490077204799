<template>
  <svg
    class="svg-icon"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 14.6875C15.6042 14.6875 16.1198 14.474 16.5469 14.0469C16.974 13.6198 17.1875 13.1042 17.1875 12.5C17.1875 11.8958 16.974 11.3802 16.5469 10.9531C16.1198 10.526 15.6042 10.3125 15 10.3125C14.3958 10.3125 13.8802 10.526 13.4531 10.9531C13.026 11.3802 12.8125 11.8958 12.8125 12.5C12.8125 13.1042 13.026 13.6198 13.4531 14.0469C13.8802 14.474 14.3958 14.6875 15 14.6875ZM15 25.0312C17.7708 22.5104 19.8177 20.224 21.1406 18.1719C22.4635 16.1198 23.125 14.3125 23.125 12.75C23.125 10.2917 22.3385 8.28125 20.7656 6.71875C19.1927 5.15625 17.2708 4.375 15 4.375C12.7292 4.375 10.8073 5.15625 9.23438 6.71875C7.66146 8.28125 6.875 10.2917 6.875 12.75C6.875 14.3125 7.55208 16.1198 8.90625 18.1719C10.2604 20.224 12.2917 22.5104 15 25.0312ZM15.0312 27.125C14.8854 27.125 14.75 27.1042 14.625 27.0625C14.5 27.0208 14.3854 26.9583 14.2812 26.875C11.1771 24.1458 8.85417 21.6146 7.3125 19.2812C5.77083 16.9479 5 14.7708 5 12.75C5 9.625 6.00521 7.13542 8.01562 5.28125C10.026 3.42708 12.3542 2.5 15 2.5C17.6458 2.5 19.974 3.42708 21.9844 5.28125C23.9948 7.13542 25 9.625 25 12.75C25 14.7708 24.2292 16.9479 22.6875 19.2812C21.1458 21.6146 18.8229 24.1458 15.7188 26.875C15.6146 26.9583 15.5052 27.0208 15.3906 27.0625C15.276 27.1042 15.1562 27.125 15.0312 27.125Z"
    />
  </svg>
</template>
