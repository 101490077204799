<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    width="24"
    height="24"
    fill="currentColor"
    viewBox="0 0 6.35 6.35"
  >
    <path
      d="M.794 2.91h4.76v.529H.794zM.794 1.46h4.76v.529H.794zM.794 4.37h4.76v.529H.794z"
    />
  </svg>
</template>
