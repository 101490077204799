<template>
  <svg
    class="svg-icon"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 44C10.1667 44 9.45833 43.7083 8.875 43.125C8.29167 42.5417 8 41.8333 8 41V19.3C8 18.4667 8.29167 17.7583 8.875 17.175C9.45833 16.5917 10.1667 16.3 11 16.3H14.5V11.5C14.5 8.86667 15.425 6.625 17.275 4.775C19.125 2.925 21.3667 2 24 2C26.6333 2 28.875 2.925 30.725 4.775C32.575 6.625 33.5 8.86667 33.5 11.5V16.3H37C37.8333 16.3 38.5417 16.5917 39.125 17.175C39.7083 17.7583 40 18.4667 40 19.3V41C40 41.8333 39.7083 42.5417 39.125 43.125C38.5417 43.7083 37.8333 44 37 44H11ZM11 41H37V19.3H11V41ZM24 34C25.0667 34 25.975 33.6333 26.725 32.9C27.475 32.1667 27.85 31.2833 27.85 30.25C27.85 29.25 27.475 28.3417 26.725 27.525C25.975 26.7083 25.0667 26.3 24 26.3C22.9333 26.3 22.025 26.7083 21.275 27.525C20.525 28.3417 20.15 29.25 20.15 30.25C20.15 31.2833 20.525 32.1667 21.275 32.9C22.025 33.6333 22.9333 34 24 34ZM17.5 16.3H30.5V11.5C30.5 9.7 29.8667 8.16667 28.6 6.9C27.3333 5.63333 25.8 5 24 5C22.2 5 20.6667 5.63333 19.4 6.9C18.1333 8.16667 17.5 9.7 17.5 11.5V16.3ZM11 41V19.3V41Z"
    />
  </svg>
</template>
