<template>
  <svg
    class="svg-icon red"
    width="40"
    height="40"
    viewBox="0 0 1000 1000"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M500,10C229.3,10,10,229.3,10,500c0,270.7,219.4,490,490,490c270.7,0,490-219.4,490-490C990,229.4,770.7,10,500,10z M500,920.1C268,920.1,79.9,732,79.9,500S268,79.9,500,79.9S920.1,268,920.1,500S732,920.1,500,920.1z M699.8,300.2c-13.6-13.6-35.8-13.7-49.5,0L500,450.5L349.7,300.2c-13.6-13.6-35.8-13.6-49.5,0c-13.7,13.6-13.6,35.8,0,49.5L450.5,500L300.2,650.3c-13.6,13.6-13.6,35.8,0,49.5c13.6,13.6,35.8,13.7,49.5,0L500,549.5l150.3,150.3c13.6,13.6,35.8,13.6,49.5,0c13.7-13.6,13.6-35.8,0-49.5L549.5,500l150.3-150.3C713.4,336.1,713.4,314,699.8,300.2L699.8,300.2z"
    />
  </svg>
</template>
