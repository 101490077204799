<template>
  <svg
    class="svg-icon"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.98345 11.6333L5.68345 8.33329C5.62789 8.27774 5.589 8.22218 5.56678 8.16663C5.54456 8.11107 5.53345 8.04996 5.53345 7.98329C5.53345 7.91663 5.54456 7.85551 5.56678 7.79996C5.589 7.7444 5.62789 7.68885 5.68345 7.63329L9.00011 4.31663C9.10011 4.21663 9.21956 4.16663 9.35845 4.16663C9.49734 4.16663 9.61678 4.21663 9.71678 4.31663C9.81678 4.41663 9.864 4.53885 9.85845 4.68329C9.85289 4.82774 9.80011 4.94996 9.70011 5.04996L6.76678 7.98329L9.71678 10.9333C9.81678 11.0333 9.86678 11.15 9.86678 11.2833C9.86678 11.4166 9.81678 11.5333 9.71678 11.6333C9.61678 11.7333 9.49456 11.7833 9.35011 11.7833C9.20567 11.7833 9.08345 11.7333 8.98345 11.6333Z"
    />
  </svg>
</template>
