<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    maxLength: {
      type: Number,
    },
    noResize: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    asterisk: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
    },
  },
  data() {
    return {
      isFocused: false,
      valueData: this.modelValue,
    };
  },
  watch: {
    modelValue(value) {
      this.valueData = value || "";
    },
    valueData(newValue) {
      let value = newValue || "";

      if (this.maxLength) {
        value = value.substr(0, this.maxLength);
      }

      if (value !== newValue) {
        this.valueData = value;

        if (this.blocks) {
          this.cleave.setRawValue(value);
        }
      }

      this.$emit("update:modelValue", value);
    },
  },
  computed: {
    isLabelLifted() {
      return this.isFocused || this.modelValue || this.valueData;
    },
    textareaStyle() {
      if (this.height) {
        return {
          "min-height": this.height + "px",
          height: this.height + "px",
        };
      } else {
        return {};
      }
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
      this.onFocus();
    },
    scrollToThis() {
      this.$el.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });

      this.focus();
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
  },
};
</script>

<template>
  <label
    class="text-field-wrap"
    :class="{
      'is-focused': isFocused,
      'is-label-lifted': isLabelLifted,
      'is-loading': loading,
      error: error,
    }"
  >
    <slot name="prepend" />
    <div class="content">
      <div v-if="label" class="label">
        <span class="label-text">{{ label }}</span
        ><span v-if="asterisk" class="asterisk">*</span>
      </div>
      <textarea
        ref="input"
        class="input"
        :class="{ 'no-resize': noResize }"
        :style="textareaStyle"
        v-model="valueData"
        v-bind="$attrs"
        @focus="onFocus"
        @blur="onBlur"
      >
      </textarea>
    </div>
    <slot name="append" />
  </label>
</template>

<style lang="scss" scoped>
.text-field-wrap {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: flex-start;
  background: #fff;
  border: 1px solid $color-border;
  border-radius: 0;
  /*height: 48px;*/
  box-shadow: 0 0 0 2px transparent;
  transition: box-shadow 0.3s, border-color 0.3s;

  &.is-loading::after {
    content: "";
    position: absolute;
    right: 22px;
    top: 50%;
    width: 24px;
    height: 24px;
    margin: -12px;
    box-sizing: border-box;
    border: 3px solid #000;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-radius: 12px;
    animation: loading-spin 1s infinite linear;
  }

  &.error {
    border-color: $color-alert;
  }

  .content {
    flex-grow: 1;
    flex-shrink: 1;
    position: relative;
  }

  .label {
    left: 10px;
    right: 10px;
    top: 13px;
    position: absolute;
    z-index: 1;
    font-size: 16px;
    line-height: 1.3;
    color: #000;
    transition: font-size 0.3s, top 0.3s, color 0.3s;
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
    justify-content: flex-start;

    .label-text {
      flex-grow: 0;
      flex-shrink: 1;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .asterisk {
      flex-grow: 0;
      flex-shrink: 0;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      margin-left: 5px;
      margin-right: 2px;
    }
  }

  &.is-label-lifted .label {
    top: 5px;
    font-size: 12px;
    color: $color-text-grey;
  }

  .input {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    resize: vertical;
    max-height: 500px;
    min-height: 70px;
    font-size: 16px;
    line-height: 1.3;
    padding: 0 10px 0 10px;
    margin-top: 22px;
    border: none;
    box-sizing: border-box;
    color: #000;
    background: #fff;
    box-shadow: inset 0 0 0 50px #fff;
    border-radius: 0;
    outline: none;

    &.no-resize {
      resize: none;
    }
  }
}
</style>
