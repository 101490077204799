import ky from "ky";

export default async function ajax(url, data) {
  const json = await ky
    .post(url, {
      headers: [["X-Parent-Accept", window.Application.x_parent_accept]],
      credentials: "same-origin",
      json: data,
    })
    .json();
  return json;
}
