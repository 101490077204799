<script>
import "swiper/css";
import Swiper, { FreeMode, Scrollbar } from "swiper";

export default {
  mounted() {
    this.swiper = new Swiper(this.$refs.swiper, {
      modules: [FreeMode, Scrollbar],
      slidesPerView: "auto",
      spaceBetween: 25,
      threshold: 0,
      loop: false,
      freeMode: {
        enabled: true,
        sticky: false,
      },
      scrollbar: {
        el: this.$refs.scrollbar,
        draggable: true,
      },
      breakpoints: {
        1023: {
          spaceBetween: 35,
        },
      },
    });
  },
};
</script>

<template>
  <div class="swiper looks-scroll" ref="swiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide ending"></div>
      <slot></slot>
      <div class="swiper-slide ending"></div>
    </div>
    <div class="swiper-scrollbar" ref="scrollbar"></div>
  </div>
</template>

<style lang="scss" scoped>
.looks-scroll {
  width: 100%;
  margin-bottom: 90px;
}

.ending {
  width: calc(var(--page-margin) - 35px);
}

@media (max-width: 1024px) {
  .ending {
    width: 0;
  }
}
</style>

<style lang="scss">
.looks-scroll .look-tile {
  width: 550px;
  max-width: calc(100% - var(--page-margin) - var(--page-margin));
}

.looks-scroll .swiper-scrollbar {
  position: relative;
  height: 13px;
  width: calc(100% - var(--page-margin) - var(--page-margin));
  max-width: 800px;
  margin: 88px auto 0 auto;

  &::before {
    content: "";
    display: block;
    position: absolute;
    height: 3px;
    top: 5px;
    width: 100%;
    background: #ededed;
  }
}

.looks-scroll .swiper-scrollbar-drag {
  position: relative;
  height: 13px;
  max-width: 450px;
  cursor: ew-resize;

  &::before {
    content: "";
    display: block;
    position: absolute;
    height: 3px;
    top: 5px;
    width: 100%;
    background: #000;
  }
}
</style>
