<template>
  <svg
    class="svg-icon"
    width="40"
    height="40"
    viewBox="0 0 10.6 10.6"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m2.765 7.446 4.674-4.674.374.374L3.139 7.82z" />
    <path d="m3.143 2.768 4.674 4.674-.374.374-4.674-4.674z" />
  </svg>
</template>
