<script>
import TextArea from "./TextArea.vue";
import TextField from "./TextField.vue";
import ky from "ky";
import * as EmailValidator from "email-validator";

export default {
  name: "PopupForm",
  components: { TextField, TextArea },
  props: {
    scheme: {
      type: Object,
      required: true,
    },
  },
  data() {
    const fields = {};
    if (this.scheme && this.scheme.fields) {
      for (const field of this.scheme.fields) {
        if (field.type == "hidden") {
          fields[field.name] = field.default;
        }
      }
    }

    return {
      loading: false,
      fields: fields,
      errors: {},
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    clear() {
      for (const field of this.scheme.fields) {
        this.fields[field.name] = field.default || null;
      }
    },
    clearErrors() {
      for (const field of this.scheme.fields) {
        this.errors[field.name] = null;
      }
    },
    validate() {
      let result = true;
      this.clearErrors();

      for (const field of this.scheme.fields) {
        if (
          (field.required && !this.fields[field.name]) ||
          this.fields[field.name].length < 1
        ) {
          this.errors[field.name] = "Пожалуйста, заполните это поле";
          result = false;
          continue;
        }

        if (
          field.type == "tel" &&
          this.fields[field.name] &&
          this.fields[field.name].replace(/\D/g, "").length != 11
        ) {
          this.errors[field.name] = "Неправильный номер телефона";
          result = false;
          continue;
        }

        if (
          field.type == "email" &&
          !EmailValidator.validate(this.fields[field.name])
        ) {
          this.errors[field.name] = "Неправильный адрес почты";
          result = false;
          continue;
        }
      }

      return result;
    },
    async submit() {
      if (!this.validate()) {
        return;
      }

      const data = Object.assign(
        {
          form: this.fields,
        },
        window.Application.config
      );

      this.loading = true;
      const json = await ky.post(this.scheme.submitUrl, { json: data }).json();
      this.loading = false;

      if (json && json.result && json.result == "success") {
        alert(json.msg);
        this.close();
      } else {
        if (json && json.error) {
          alert(json.error);
        } else {
          alert("Произошла ошибка");
        }
      }
    },
  },
  beforeMount() {
    this.clear();
    this.clearErrors();
  },
  mounted() {
    document.body.classList.add("noscroll");
  },
  unmounted() {
    setTimeout(() => {
      document.body.classList.remove("noscroll");
    }, 500);
  },
};
</script>

<template>
  <div class="form-overlay" @click="close">
    <div class="form" @click.stop>
      <transition name="fade-03">
        <div v-if="loading" class="loading"></div>
      </transition>
      <button type="button" class="close-button" @click="close">
        <icon-close-popup></icon-close-popup>
      </button>
      <div class="form-title">{{ scheme.title }}</div>
      <div class="form-description">{{ scheme.description }}</div>
      <div class="form-rows">
        <template v-for="field in scheme.fields">
          <template v-if="field.type == 'hidden'"></template>
          <template v-else-if="field.type == 'area'">
            <div class="form-row" :key="field.name">
              <text-area
                :label="field.label"
                v-model="fields[field.name]"
                no-resize
              ></text-area>
              <div v-if="errors[field.name]" class="error">
                {{ errors[field.name] }}
              </div>
            </div>
          </template>
          <template v-else-if="field.type == 'tel'">
            <div class="form-row" :key="field.name">
              <text-field
                mask="+7 (999) 999-99-99"
                :label="field.label"
                v-model="fields[field.name]"
              ></text-field>
              <div v-if="errors[field.name]" class="error">
                {{ errors[field.name] }}
              </div>
            </div>
          </template>
          <template v-else-if="field.type == 'email'">
            <div class="form-row" :key="field.name">
              <text-field
                type="email"
                :label="field.label"
                v-model="fields[field.name]"
              ></text-field>
              <div v-if="errors[field.name]" class="error">
                {{ errors[field.name] }}
              </div>
            </div>
          </template>
          <template v-else>
            <div class="form-row" :key="field.name">
              <text-field
                :label="field.label"
                v-model="fields[field.name]"
              ></text-field>
              <div v-if="errors[field.name]" class="error">
                {{ errors[field.name] }}
              </div>
            </div>
          </template>
        </template>
      </div>
      <button type="button" class="form-button" @click="submit">
        {{ scheme.button }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  background: $color-overlay;
  z-index: 200;
  overflow-x: hidden;
  overflow-y: auto;
}

.form {
  position: relative;
  padding: 40px 50px 40px 50px;
  background: #fff;
  box-shadow: 5px 5px 21px rgba(0, 0, 0, 0.15);
  max-width: 300px;
}

.loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  z-index: 50;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    border: 3px solid #000;
    margin: -15px;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-radius: 50%;
    animation: spin 0.75s linear infinite;
  }
}

.error {
  font-size: 12px;
  color: $color-alert;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  background: none;
  cursor: pointer;

  .svg-icon {
    display: block;
  }
}

.form-title {
  text-align: center;
  font-weight: 900;
  font-size: 14px;
  line-height: 1.2;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.form-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  color: $color-text-grey;
  margin-bottom: 15px;
}

.form-row {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.form-button {
  @include black-button;
  display: block;
  height: 50px;
  margin-top: 20px;
  width: 100%;
}
</style>
