export default function (product, color) {
  return {
    productId: product.id,
    colorId: color.uuid,
    sku: color.sku,
    title: product.title,
    description: product.description,
    notAvailable: !!color.not_available,
    colorTitle: color.title,
    price: product.price,
    priceOld: product.price_old,
    url: null,
    coverSrc: null,
  };
}
