<template>
  <svg
    class="svg-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.975 13.175L9 11.2C8.85 11.05 8.66667 10.975 8.45 10.975C8.23333 10.975 8.05 11.05 7.9 11.2C7.75 11.35 7.675 11.5333 7.675 11.75C7.675 11.9666 7.75 12.15 7.9 12.3L10.375 14.775C10.525 14.925 10.7042 15 10.9125 15C11.1208 15 11.3 14.925 11.45 14.775L16.075 10.15C16.2083 10.0166 16.275 9.84162 16.275 9.62495C16.275 9.40828 16.2083 9.23328 16.075 9.09995C15.9417 8.96662 15.7708 8.89995 15.5625 8.89995C15.3542 8.89995 15.1833 8.96662 15.05 9.09995L10.975 13.175ZM12 21.9249C11.9333 21.9249 11.8708 21.9208 11.8125 21.9125C11.7542 21.9041 11.7 21.8916 11.65 21.875C9.41667 21.2083 7.58333 19.8375 6.15 17.7625C4.71667 15.6875 4 13.4083 4 10.925V6.02495C4 5.70828 4.09167 5.42078 4.275 5.16245C4.45833 4.90412 4.69167 4.71662 4.975 4.59995L11.475 2.17495C11.6583 2.10828 11.8333 2.07495 12 2.07495C12.1667 2.07495 12.3417 2.10828 12.525 2.17495L19.025 4.59995C19.3083 4.71662 19.5417 4.90412 19.725 5.16245C19.9083 5.42078 20 5.70828 20 6.02495V10.925C20 13.4083 19.2833 15.6875 17.85 17.7625C16.4167 19.8375 14.5833 21.2083 12.35 21.875L12 21.9249ZM12 20.4249C13.9167 19.7916 15.4792 18.5958 16.6875 16.8375C17.8958 15.0791 18.5 13.1083 18.5 10.925V6.02495L12 3.57495L5.5 6.02495V10.925C5.5 13.1083 6.10417 15.0791 7.3125 16.8375C8.52083 18.5958 10.0833 19.7916 12 20.4249Z"
    />
  </svg>
</template>
