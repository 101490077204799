<template>
  <svg
    class="svg-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.7501 5.5C4.53343 5.5 4.35426 5.42917 4.2126 5.2875C4.07093 5.14583 4.0001 4.96667 4.0001 4.75C4.0001 4.53333 4.07093 4.35417 4.2126 4.2125C4.35426 4.07083 4.53343 4 4.7501 4H19.3001C19.5168 4 19.6959 4.07083 19.8376 4.2125C19.9793 4.35417 20.0501 4.53333 20.0501 4.75C20.0501 4.96667 19.9793 5.14583 19.8376 5.2875C19.6959 5.42917 19.5168 5.5 19.3001 5.5H4.7501ZM4.8751 20C4.65843 20 4.47926 19.9292 4.3376 19.7875C4.19593 19.6458 4.1251 19.4667 4.1251 19.25V13.55H3.5001C3.26676 13.55 3.0751 13.4583 2.9251 13.275C2.7751 13.0917 2.7251 12.8833 2.7751 12.65L3.8751 7.6C3.90843 7.41667 3.99593 7.27083 4.1376 7.1625C4.27926 7.05417 4.43343 7 4.6001 7H19.4251C19.6084 7 19.7668 7.05417 19.9001 7.1625C20.0334 7.27083 20.1168 7.41667 20.1501 7.6L21.2501 12.65C21.3001 12.8833 21.2501 13.0917 21.1001 13.275C20.9501 13.4583 20.7584 13.55 20.5251 13.55H19.9001V19.25C19.9001 19.4667 19.8293 19.6458 19.6876 19.7875C19.5459 19.9292 19.3668 20 19.1501 20C18.9334 20 18.7543 19.9292 18.6126 19.7875C18.4709 19.6458 18.4001 19.4667 18.4001 19.25V13.55H13.6751V19.25C13.6751 19.4667 13.6043 19.6458 13.4626 19.7875C13.3209 19.9292 13.1418 20 12.9251 20H4.8751ZM5.6251 18.5H12.1751V13.55H5.6251V18.5ZM4.3751 12.05H19.6501H4.3751ZM4.3751 12.05H19.6501L18.8751 8.5H5.1501L4.3751 12.05Z"
    />
  </svg>
</template>
