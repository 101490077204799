<template>
  <svg
    class="svg-icon"
    width="12"
    height="20"
    viewBox="0 0 12 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4457 10.1054C11.4457 9.93869 11.418 9.78591 11.3624 9.64702C11.3068 9.50813 11.2096 9.36924 11.0707 9.23035L2.82073 0.980351C2.59851 0.758129 2.2999 0.653963 1.9249 0.667851C1.5499 0.68174 1.25129 0.799796 1.02907 1.02202C0.751289 1.2998 0.633234 1.59841 0.6749 1.91785C0.716567 2.2373 0.848512 2.52202 1.07073 2.77202L8.40407 10.1054L1.07073 17.4387C0.848512 17.6609 0.723511 17.9595 0.695733 18.3345C0.667955 18.7095 0.792956 19.0081 1.07073 19.2304C1.29296 19.5081 1.58462 19.6262 1.94573 19.5845C2.30684 19.5429 2.6124 19.4109 2.8624 19.1887L11.0707 10.9804C11.2096 10.8415 11.3068 10.7026 11.3624 10.5637C11.418 10.4248 11.4457 10.272 11.4457 10.1054Z"
    />
  </svg>
</template>
