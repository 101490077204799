<template>
  <svg
    class="svg-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.1749 21.975C6.6749 21.975 6.2499 21.8 5.8999 21.45C5.5499 21.1 5.3749 20.675 5.3749 20.175C5.3749 19.675 5.5499 19.25 5.8999 18.9C6.2499 18.55 6.6749 18.375 7.1749 18.375C7.65824 18.375 8.07907 18.55 8.4374 18.9C8.79574 19.25 8.9749 19.675 8.9749 20.175C8.9749 20.675 8.7999 21.1 8.4499 21.45C8.0999 21.8 7.6749 21.975 7.1749 21.975ZM17.1749 21.975C16.6749 21.975 16.2499 21.8 15.8999 21.45C15.5499 21.1 15.3749 20.675 15.3749 20.175C15.3749 19.675 15.5499 19.25 15.8999 18.9C16.2499 18.55 16.6749 18.375 17.1749 18.375C17.6582 18.375 18.0791 18.55 18.4374 18.9C18.7957 19.25 18.9749 19.675 18.9749 20.175C18.9749 20.675 18.7999 21.1 18.4499 21.45C18.0999 21.8 17.6749 21.975 17.1749 21.975ZM5.8749 5.475L8.6249 11.175H15.8249L18.9499 5.475H5.8749ZM6.9249 16.775C6.2249 16.775 5.72074 16.5417 5.4124 16.075C5.10407 15.6083 5.10824 15.0833 5.4249 14.5L7.0249 11.55L3.2249 3.5H1.9999C1.7999 3.5 1.62907 3.425 1.4874 3.275C1.34574 3.125 1.2749 2.94167 1.2749 2.725C1.2749 2.525 1.34574 2.35417 1.4874 2.2125C1.62907 2.07083 1.80824 2 2.0249 2H3.7249C3.8749 2 4.00824 2.0375 4.1249 2.1125C4.24157 2.1875 4.33324 2.29167 4.3999 2.425L5.1249 3.975H19.8499C20.3832 3.975 20.7207 4.1375 20.8624 4.4625C21.0041 4.7875 20.9582 5.15 20.7249 5.55L17.3499 11.625C17.1832 11.9083 16.9499 12.1542 16.6499 12.3625C16.3499 12.5708 16.0249 12.675 15.6749 12.675H8.0999L6.6999 15.275H18.2499C18.4499 15.275 18.6207 15.35 18.7624 15.5C18.9041 15.65 18.9749 15.825 18.9749 16.025C18.9749 16.2417 18.9041 16.4208 18.7624 16.5625C18.6207 16.7042 18.4416 16.775 18.2249 16.775H6.9249ZM8.6249 11.175H15.8249H8.6249Z"
    />
  </svg>
</template>
