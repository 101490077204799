<script>
export default {
  props: {
    dialog: {
      type: Object,
    },
  },
  data() {
    return {
      isOpen: false,
      shake: false,
    };
  },
  methods: {
    buttonClick(key) {
      if (this.dialog.callback) {
        this.dialog.callback(key);
      }
      this.isOpen = false;
      setTimeout(() => {
        this.$emit("close");
      }, 300);
    },
    overlayClick() {
      if (this.dialog.options.close) {
        this.buttonClick(null);
      } else {
        this.shake = true;
        setTimeout(() => {
          this.shake = false;
        }, 1000);
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.isOpen = true;
    }, 10);
  },
};
</script>

<template>
  <div
    class="popup-dialog-overlay"
    :class="{ open: isOpen }"
    @click="overlayClick"
  >
    <div class="popup-dialog" :class="{ shake: shake }" @click.stop>
      <div class="content">
        <article
          v-if="dialog.options.html"
          class="text html"
          v-bind:class="dialog.options.textClass || undefined"
          v-html="dialog.text"
        ></article>
        <div
          v-else
          class="text simple"
          v-bind:class="dialog.options.textClass || undefined"
        >
          {{ dialog.text }}
        </div>
        <div
          class="actions"
          v-if="!dialog.options.buttons || dialog.options.buttons.length > 0"
        >
          <template v-if="dialog.options.buttons">
            <button
              v-for="(button, index) in dialog.options.buttons"
              :key="index + ':' + button.key + ':' + button.label"
              type="button"
              class="button"
              @click="buttonClick(button.key)"
            >
              {{ button.label }}
            </button>
          </template>
          <template v-else>
            <button type="button" class="button" @click="buttonClick(null)">
              Ok
            </button>
          </template>
        </div>
        <button
          v-if="dialog.options.close"
          type="button"
          class="close"
          @click="buttonClick(null)"
        >
          <icon-close-24 />
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.popup-dialog-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 200;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.3s;
}

.popup-dialog-overlay.open {
  opacity: 1;
}

.popup-dialog {
  background: #fff;
  max-width: calc(100vw - 50px);
  max-height: 90%;
  max-height: calc(100vh - 50px);
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 5px 5px 21px rgba(0, 0, 0, 0.15);
}

.popup-dialog.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.content {
  position: relative;
  padding: 50px;
  max-width: 800px;
}

.text {
  &.no-margin {
    margin-bottom: 0;
  }
}

.text.simple {
  text-align: center;
}

.text.html {
  text-align: center;
}

.actions {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: center;
  margin-top: 30px;

  .button {
    @include black-button;
    height: 50px;
    padding: 0 20px 0 20px;
    min-width: 120px;
  }
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 44px;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  color: #000;
  cursor: pointer;
  transition: 0.3s;

  /*
  &:hover {
    color: $color-secondary;
  }
  */
}
</style>

<style lang="scss">
.popup-dialog .text.html p {
  margin-top: 0;
  margin-bottom: 11px;

  &:last-child {
    margin-bottom: 0;
  }
}

.popup-dialog .text.html ul:first-child,
.popup-dialog .text.html ol:first-child,
.popup-dialog .text.html h1:first-child,
.popup-dialog .text.html h2:first-child,
.popup-dialog .text.html h3:first-child,
.popup-dialog .text.html h4:first-child,
.popup-dialog .text.html h5:first-child,
.popup-dialog .text.html h6:first-child {
  margin-top: 0;
}

.popup-dialog .text.html ul:last-child,
.popup-dialog .text.html ol:last-child,
.popup-dialog .text.html h1:last-child,
.popup-dialog .text.html h2:last-child,
.popup-dialog .text.html h3:last-child,
.popup-dialog .text.html h4:last-child,
.popup-dialog .text.html h5:last-child,
.popup-dialog .text.html h6:last-child {
  margin-bottom: 0;
}

.popup-dialog .text.html h1 {
  font-weight: 900;
  font-size: 24px;
  line-height: 1.2;
  margin: 0 0 11px 0;
}

.popup-dialog .text.html p {
  color: $color-text-grey;
}

.popup-dialog .text.html .center {
  text-align: center;
}
</style>
