<script>
import ky from "ky";
import * as _ from "lodash-es";

export default {
  name: "FaqItem",
  props: {
    initOpen: {
      type: Boolean,
      default: false,
    },
    item: Object,
  },
  data() {
    return {
      loading: false,
      query: "",
      suggestions: [],
    };
  },
  watch: {
    query: _.debounce(function (queryString) {
      this.update(queryString);
    }, 500),
  },
  methods: {
    onFocus() {
      if (this.query && this.query.length > 0) {
        this.update(this.query);
      }
    },
    onBlur() {
      setTimeout(() => {
        this.suggestions = [];
      }, 200);
    },
    async update(queryString) {
      if (queryString.length < 2) {
        this.suggestions = [];
        return;
      }

      this.loading = true;
      const json = await ky
        .post("/ajax/faq/search.php", { json: { queryString: queryString } })
        .json();
      this.loading = false;

      if (json.result && json.result == "success") {
        this.suggestions = json.found || [];
      } else {
        this.suggestions = [];
      }
    },
  },
};
</script>

<template>
  <div class="faq-search">
    <div class="block-title">Как мы можем вам помочь?</div>
    <div class="field-wrap">
      <div
        class="field-popup"
        :class="{ open: suggestions && suggestions.length > 0 }"
      >
        <div class="field-row">
          <icon-search></icon-search>
          <input
            type="text"
            class="field"
            v-model="query"
            placeholder="Найти ..."
            @blur="onBlur"
            @focus="onFocus"
          />
          <div class="loading" :class="{ show: loading }"></div>
        </div>
        <div v-if="suggestions && suggestions.length > 0" class="suggestions">
          <div
            v-for="suggestion in suggestions"
            class="suggestion"
            :key="suggestion.title"
          >
            <a :href="suggestion.url">{{ suggestion.title }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.field-popup {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  border-radius: 3px;
  background: #fff;
  z-index: 1;

  &.open {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
}

.field-row {
  @include flex-row;

  align-items: center;
  justify-content: flex-start;
  height: 50px;
  padding-left: 17px;

  .svg-icon {
    margin-right: 9px;
  }

  .field {
    flex-grow: 1;
    flex-shrink: 1;
    border: none;
    font-weight: 400;
    font-size: 16px;
    padding: 0;
  }
}

.suggestions {
  max-height: 237px;
  overflow-x: hidden;
  overflow-y: auto;
  border-top: 1px solid $color-border;
}

.suggestion {
  border-bottom: 1px solid $color-border;
  padding: 12px 30px 12px 30px;

  a {
    text-decoration: none;
    color: #000;

    &:hover {
      text-decoration: underline;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}

.loading {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: 2px solid #000;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  margin-right: 15px;
  margin-left: 10px;
  animation: spin 0.75s linear infinite;
  opacity: 0;
  transition: opacity 0.3s;

  &.show {
    opacity: 1;
  }
}
</style>
