<template>
  <svg
    class="svg-icon"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 13H3.73333L11.1167 5.61662L10.3833 4.88329L3 12.2666V13ZM13.2333 4.89995L11.1 2.76662L11.8 2.06662C11.9889 1.87773 12.225 1.78606 12.5083 1.79162C12.7917 1.79718 13.0278 1.8944 13.2167 2.08329L13.9333 2.79995C14.1222 2.98884 14.2167 3.22218 14.2167 3.49995C14.2167 3.77773 14.1222 4.01106 13.9333 4.19995L13.2333 4.89995ZM2.5 14C2.35556 14 2.23611 13.9527 2.14167 13.8583C2.04722 13.7638 2 13.6444 2 13.5V12.0666C2 12 2.01111 11.9388 2.03333 11.8833C2.05556 11.8277 2.09444 11.7722 2.15 11.7166L10.4 3.46662L12.5333 5.59995L4.28333 13.85C4.22778 13.9055 4.17222 13.9444 4.11667 13.9666C4.06111 13.9888 4 14 3.93333 14H2.5ZM10.75 5.24995L10.3833 4.88329L11.1167 5.61662L10.75 5.24995Z"
    />
  </svg>
</template>
