<template>
  <svg
    class="svg-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.75009 21.3C6.60009 21.4167 6.45425 21.4209 6.31259 21.3125C6.17092 21.2042 6.12509 21.0584 6.17509 20.875L8.15009 14.4L2.95009 10.675C2.80009 10.575 2.75425 10.4375 2.81259 10.2625C2.87092 10.0875 2.99175 10 3.17509 10H9.60009L11.6501 3.20005C11.6834 3.11672 11.7293 3.05005 11.7876 3.00005C11.8459 2.95005 11.9168 2.92505 12.0001 2.92505C12.0834 2.92505 12.1543 2.95005 12.2126 3.00005C12.2709 3.05005 12.3168 3.11672 12.3501 3.20005L14.4001 10H20.8251C21.0084 10 21.1293 10.0875 21.1876 10.2625C21.2459 10.4375 21.2001 10.575 21.0501 10.675L15.8501 14.4L17.8251 20.875C17.8751 21.0584 17.8293 21.2042 17.6876 21.3125C17.5459 21.4209 17.4001 21.4167 17.2501 21.3L12.0001 17.3L6.75009 21.3ZM8.57509 17.925L12.0001 15.325L15.4251 17.925L14.0501 13.6L17.2001 11.55H13.4251L12.0001 7.22505L10.5751 11.55H6.80009L9.95009 13.6L8.57509 17.925Z"
    />
  </svg>
</template>
