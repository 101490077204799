<template>
  <svg
    class="svg-icon"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.43687 26.625C8.24937 26.7708 8.06708 26.776 7.89 26.6406C7.71292 26.5052 7.65562 26.3229 7.71813 26.0938L10.1869 18L3.68688 13.3438C3.49938 13.2188 3.44208 13.0469 3.515 12.8281C3.58792 12.6094 3.73896 12.5 3.96812 12.5H11.9994L14.5619 4C14.6035 3.89583 14.6608 3.8125 14.7338 3.75C14.8067 3.6875 14.8952 3.65625 14.9994 3.65625C15.1035 3.65625 15.1921 3.6875 15.265 3.75C15.3379 3.8125 15.3952 3.89583 15.4369 4L17.9994 12.5H26.0306C26.2598 12.5 26.4108 12.6094 26.4837 12.8281C26.5567 13.0469 26.4994 13.2188 26.3119 13.3438L19.8119 18L22.2806 26.0938C22.3431 26.3229 22.2858 26.5052 22.1087 26.6406C21.9317 26.776 21.7494 26.7708 21.5619 26.625L14.9994 21.625L8.43687 26.625ZM10.7181 22.4062L14.9994 19.1562L19.2806 22.4062L17.5619 17L21.4994 14.4375H16.7806L14.9994 9.03125L13.2181 14.4375H8.49938L12.4369 17L10.7181 22.4062Z"
    />
  </svg>
</template>
