<template>
  <svg
    class="svg-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 17.425C13.35 17.275 13.275 17.0958 13.275 16.8875C13.275 16.6792 13.3417 16.5083 13.475 16.375L17.15 12.7H4.75C4.53333 12.7 4.35417 12.6292 4.2125 12.4875C4.07083 12.3458 4 12.1667 4 11.95C4 11.7333 4.07083 11.5542 4.2125 11.4125C4.35417 11.2708 4.53333 11.2 4.75 11.2H17.15L13.45 7.5C13.3 7.35 13.2292 7.175 13.2375 6.975C13.2458 6.775 13.325 6.6 13.475 6.45C13.625 6.31667 13.8042 6.25 14.0125 6.25C14.2208 6.25 14.3917 6.31667 14.525 6.45L19.5 11.425C19.5833 11.5083 19.6417 11.5917 19.675 11.675C19.7083 11.7583 19.725 11.85 19.725 11.95C19.725 12.05 19.7083 12.1417 19.675 12.225C19.6417 12.3083 19.5833 12.3917 19.5 12.475L14.55 17.425C14.4 17.575 14.225 17.65 14.025 17.65C13.825 17.65 13.65 17.575 13.5 17.425Z"
    />
  </svg>
</template>
