<template>
  <svg
    class="svg-icon green"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.05 24.55L12.15 19.65C11.85 19.35 11.4833 19.2 11.05 19.2C10.6167 19.2 10.2333 19.3667 9.9 19.7C9.56667 20.0333 9.4 20.4167 9.4 20.85C9.4 21.2833 9.56667 21.65 9.9 21.95L16 28.05C16.3 28.35 16.65 28.5 17.05 28.5C17.45 28.5 17.8 28.35 18.1 28.05L30.1 16.05C30.4 15.75 30.5417 15.3833 30.525 14.95C30.5083 14.5167 30.35 14.15 30.05 13.85C29.75 13.5167 29.375 13.35 28.925 13.35C28.475 13.35 28.0833 13.5167 27.75 13.85L17.05 24.55ZM20 40C17.1667 40 14.5333 39.4917 12.1 38.475C9.66667 37.4583 7.55 36.05 5.75 34.25C3.95 32.45 2.54167 30.3333 1.525 27.9C0.508333 25.4667 0 22.8333 0 20C0 17.2 0.508333 14.5833 1.525 12.15C2.54167 9.71667 3.95 7.6 5.75 5.8C7.55 4 9.66667 2.58333 12.1 1.55C14.5333 0.516667 17.1667 0 20 0C22.8 0 25.4167 0.516667 27.85 1.55C30.2833 2.58333 32.4 4 34.2 5.8C36 7.6 37.4167 9.71667 38.45 12.15C39.4833 14.5833 40 17.2 40 20C40 22.8333 39.4833 25.4667 38.45 27.9C37.4167 30.3333 36 32.45 34.2 34.25C32.4 36.05 30.2833 37.4583 27.85 38.475C25.4167 39.4917 22.8 40 20 40ZM20 37C24.8333 37 28.875 35.375 32.125 32.125C35.375 28.875 37 24.8333 37 20C37 15.1667 35.375 11.125 32.125 7.875C28.875 4.625 24.8333 3 20 3C15.1667 3 11.125 4.625 7.875 7.875C4.625 11.125 3 15.1667 3 20C3 24.8333 4.625 28.875 7.875 32.125C11.125 35.375 15.1667 37 20 37Z"
    />
  </svg>
</template>
