<template>
  <svg
    class="svg-icon"
    width="39"
    height="24"
    viewBox="0 0 39 24"
    fill="currentColor"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.7578 1.00002L26.7594 1.00001C32.8172 0.990149 37.7362 5.91405 37.7362 12C37.7362 18.0742 32.8177 23 26.7578 23C20.6978 23 15.7793 18.0742 15.7793 12C15.7793 5.92573 20.6978 1.00002 26.7578 1.00002Z"
      fill="none"
      stroke="white"
      stroke-width="2"
    />
    <path
      d="M23.9569 12C23.9569 18.6247 18.5925 24 11.9785 24C5.36445 24 0 18.6247 0 12C0 5.37518 5.36445 0 11.9785 0C18.5925 0 23.9569 5.36441 23.9569 12Z"
      stroke-width="0"
    />
    <path
      d="M19.3681 2.54199C16.5782 4.73948 14.7793 8.15423 14.7793 11.9891C14.7793 15.8239 16.5782 19.2386 19.3681 21.4361C22.1581 19.2386 23.957 15.8239 23.957 11.9891C23.957 8.15423 22.1581 4.73948 19.3681 2.54199Z"
      stroke-width="0"
    />
  </svg>
</template>
