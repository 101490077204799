<script>
import "swiper/css";
import Swiper, { Autoplay, EffectFade } from "swiper";

export default {
  props: {
    settings: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.swiper = new Swiper(this.$refs.swiper, {
      modules: [Autoplay, EffectFade],
      //effect: 'fade',
      slidesPerView: 1,
      spaceBetween: 0,
      threshold: 10,
      loop: true,
      speed: this.settings.transition,
      autoplay: {
        delay: this.settings.interval,
      },
    });

    this.$el.addEventListener("click", (event) => {
      if (event.target.matches(".nav-button.prev, .nav-button.prev *")) {
        this.swiper.slidePrev(this.settings.transition);
      } else if (event.target.matches(".nav-button.next, .nav-button.next *")) {
        this.swiper.slideNext(this.settings.transition);
      }
    });
  },
};
</script>

<template>
  <div class="slider-wrapper">
    <div class="swiper" ref="swiper">
      <div class="swiper-wrapper">
        <slot></slot>
      </div>
    </div>
    <button class="nav-button prev" type="button">
      <icon-arrow-prev></icon-arrow-prev>
    </button>
    <button class="nav-button next" type="button">
      <icon-arrow-next></icon-arrow-next>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.swiper {
  width: 100%;
}
</style>
