export default function findProductInCart(cart, productId, colorId) {
  if (cart) {
    for (const index in cart) {
      const item = cart[index];
      if (item.productId == productId && item.colorId == colorId) {
        return index;
      }
    }
  }

  return null;
}
