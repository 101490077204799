<template>
  <svg
    class="svg-icon"
    width="75"
    height="24"
    viewBox="0 0 75 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.9707 0.411373L26.1983 23.5886H32.2289L36.0013 0.411373H29.9707ZM21.1217 0.411373L15.3711 16.3501L14.6884 12.919L14.5396 12.1488C13.8394 10.6521 12.2289 7.79868 9.18294 5.36542C8.28141 4.64769 7.37112 4.03501 6.49585 3.50984L11.7212 23.5974H18.0057L27.6075 0.420128H21.1217V0.411373ZM44.6753 6.84463C44.6753 4.22756 50.5484 4.56017 53.1392 5.98687L53.9969 1.00656C53.9969 1.00656 51.3361 0 48.5703 0C45.5768 0 38.4608 1.3129 38.4608 7.67614C38.4608 13.663 46.811 13.7418 46.811 16.884C46.811 20.0263 39.3274 19.4661 36.8503 17.4792L35.9488 22.6871C35.9488 22.6871 38.6446 24 42.7584 24C46.8722 24 53.0867 21.8643 53.0867 16.0613C53.0954 10.0306 44.6753 9.47045 44.6753 6.84463ZM69.2967 0.411373H64.4477C62.207 0.411373 61.6643 2.13567 61.6643 2.13567L52.6665 23.5886H58.9597L60.2201 20.1488H67.8963L68.6053 23.5886H74.1457L69.2967 0.411373ZM61.9532 15.3961L65.1217 6.72209L66.9072 15.3961H61.9532Z"
    />
    <path
      d="M12.7451 2.59972C12.7451 2.59972 12.4912 0.507812 9.83041 0.507812H0.141127L0.0273438 0.90169C0.0273438 0.90169 4.6838 1.84698 9.15645 5.40934C13.4278 8.8054 14.8195 13.0505 14.8195 13.0505L12.7451 2.59972Z"
      fill="#C7C7C7"
    />
  </svg>
</template>
