<template>
  <svg
    class="svg-icon"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.079 10.9158C14.954 10.9158 14.8395 10.9366 14.7353 10.9783C14.6311 11.0199 14.527 11.0928 14.4228 11.197L8.23529 17.3845C8.06863 17.5512 7.9905 17.7751 8.00092 18.0564C8.01134 18.3376 8.09988 18.5616 8.26654 18.7283C8.47488 18.9366 8.69884 19.0251 8.93842 18.9939C9.178 18.9626 9.39154 18.8637 9.57904 18.697L15.079 13.197L20.579 18.697C20.7457 18.8637 20.9697 18.9574 21.2509 18.9783C21.5322 18.9991 21.7561 18.9053 21.9228 18.697C22.1311 18.5303 22.2197 18.3116 22.1884 18.0408C22.1572 17.7699 22.0582 17.5408 21.8915 17.3533L15.7353 11.197C15.6311 11.0928 15.527 11.0199 15.4228 10.9783C15.3186 10.9366 15.204 10.9158 15.079 10.9158V10.9158Z"
    />
  </svg>
</template>
