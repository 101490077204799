<script>
export default {
  name: "StdLink",
  props: ["href", "rel", "target"],
  computed: {
    isExternal() {
      const url = new URL(this.href, window.location.href);
      return url.host !== window.location.host;
    },
    targetOut() {
      if (this.target) {
        return this.target;
      }

      if (!this.href) {
        return undefined;
      }

      if (this.isExternal) {
        return "_blank";
      }

      return undefined;
    },
    relOut() {
      if (this.rel) {
        return this.rel;
      }

      if (!this.href) {
        return undefined;
      }

      if (this.isExternal) {
        return "nofollow";
      }

      return undefined;
    },
  },
};
</script>

<template>
  <a v-bind:href="href" v-bind:rel="relOut" v-bind:target="targetOut"
    ><slot
  /></a>
</template>
