<script>
function encodeRFC3986URIComponent(str) {
  return encodeURIComponent(str).replace(
    /[!'()*]/g,
    (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
  );
}

export default {
  name: "SearchField",
  data() {
    return {
      keyphrase: "",
      isOpen: false,
      overlayHeight: 0,
      error: false,
    };
  },
  methods: {
    open() {
      this.isOpen = true;
      document.body.classList.add("noscroll");
      this.updateHeight();
      this.$refs.field.focus();
    },
    close() {
      this.isOpen = false;
      document.body.classList.remove("noscroll");
    },
    updateHeight() {
      this.overlayHeight =
        window.innerHeight - this.$el.getBoundingClientRect().top - 71 + "px";
    },
    submit() {
      if (this.isOpen) {
        if (this.keyphrase && this.keyphrase.length > 0) {
          window.location =
            "/catalog/?search=" + encodeRFC3986URIComponent(this.keyphrase);
        } else {
          this.error = true;
          this.$refs.field.focus();
        }
      } else {
        this.$refs.field.focus();
      }
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      if (this.isOpen) {
        this.updateHeight();
      }
    });
  },
};
</script>

<template>
  <div class="search-screen">
    <div
      class="search-screen-overlay"
      :class="{ open: isOpen }"
      :style="{ height: overlayHeight }"
      @click="close"
    ></div>
    <div class="search-top-panel" :class="{ open: isOpen }">
      <button type="button" class="icon-button close-button" @click="close">
        <icon-close24 />
      </button>
    </div>
    <form
      class="search-field"
      :class="{ open: isOpen, error: error }"
      @submit.prevent="submit"
    >
      <div class="field-wrapper">
        <input
          class="field"
          ref="field"
          placeholder="Найти в каталоге ..."
          v-model="keyphrase"
          @focus="open"
          @input="error = false"
        />
      </div>
      <button type="submit" class="submit-button">
        <icon-search class="icon" />
      </button>
    </form>
    <button type="button" class="open-button" @click="open">
      <icon-search class="icon" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
.search-screen {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 0;
}

.search-screen-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 70px;
  background: $color-overlay;
  height: 500px;
  z-index: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s;

  &.open {
    visibility: visible;
    opacity: 1;
  }
}

.search-top-panel {
  @include flex-row;

  position: relative;
  height: 69px;
  padding: 0 var(--page-margin) 0 var(--page-margin);
  z-index: 1;
  background: #fff;
  border-bottom: 1px solid $color-border;
  align-items: center;
  justify-content: flex-end;
  visibility: hidden;
  opacity: 0;
  transition: height 0.3s, visibility 0.3s, opacity 0.3s;

  &.open {
    height: 150px;
    visibility: visible;
    opacity: 1;
  }

  .close-button {
    .svg-icon {
      width: 40px;
      height: 40px;
    }
  }
}

.search-field {
  @include flex-row;

  position: absolute;
  left: var(--page-margin);
  top: 20px;
  z-index: 2;
  width: 200px;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid $color-border;
  padding-bottom: 5px;
  transition: width 0.3s, top 0.3s;

  .icon {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: 10px;
    transition: width 0.3s, height 0.3s;
  }

  .field-wrapper {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .field {
    width: 100%;
    border: none;
    height: 20px;
    font-size: 14px;
    transition: height 0.3s, font-size 0.3s;
  }

  &.error {
    border-bottom-color: $color-alert;
  }

  &.open {
    top: 51px;
    width: 700px;
    max-width: calc(100% - 120px);

    .icon {
      width: 40px;
      height: 40px;
    }

    .field {
      height: 40px;
      font-size: 24px;
    }
  }
}

.submit-button,
.open-button {
  padding: 0;
  border: none;
  background: none;
  margin: 0;
  cursor: pointer;

  .svg-icon {
    display: block;
  }
}

.open-button {
  display: none;
  position: absolute;
  left: var(--page-margin);
  top: 13px;
}

@media (max-width: 1024px) {
  .search-field {
    visibility: hidden;

    &.open {
      visibility: visible;
    }
  }

  .open-button {
    display: block;
  }
}

@media (max-width: 800px) {
  .search-top-panel {
    &.open {
      height: 80px;
    }

    .close-button {
      .svg-icon {
        width: 24px;
        height: 24px;
      }
    }
  }

  .search-field {
    border-bottom: none;
    padding-bottom: 0;

    &.open {
      top: 30px;
      max-width: calc(100% - 85px);

      .icon {
        width: 24px;
        height: 24px;
      }

      .field {
        height: 20px;
        font-size: 14px;
      }
    }
  }
}
</style>
