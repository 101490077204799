<template>
  <svg
    class="svg-icon"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 17C15.5667 17 15.2083 16.8583 14.925 16.575C14.6417 16.2917 14.5 15.9333 14.5 15.5C14.5 15.0667 14.6417 14.7083 14.925 14.425C15.2083 14.1417 15.5667 14 16 14H40.5C40.9333 14 41.2917 14.1417 41.575 14.425C41.8583 14.7083 42 15.0667 42 15.5C42 15.9333 41.8583 16.2917 41.575 16.575C41.2917 16.8583 40.9333 17 40.5 17H16ZM16 25.5C15.5667 25.5 15.2083 25.3583 14.925 25.075C14.6417 24.7917 14.5 24.4333 14.5 24C14.5 23.5667 14.6417 23.2083 14.925 22.925C15.2083 22.6417 15.5667 22.5 16 22.5H40.5C40.9333 22.5 41.2917 22.6417 41.575 22.925C41.8583 23.2083 42 23.5667 42 24C42 24.4333 41.8583 24.7917 41.575 25.075C41.2917 25.3583 40.9333 25.5 40.5 25.5H16ZM16 34C15.5667 34 15.2083 33.8583 14.925 33.575C14.6417 33.2917 14.5 32.9333 14.5 32.5C14.5 32.0667 14.6417 31.7083 14.925 31.425C15.2083 31.1417 15.5667 31 16 31H40.5C40.9333 31 41.2917 31.1417 41.575 31.425C41.8583 31.7083 42 32.0667 42 32.5C42 32.9333 41.8583 33.2917 41.575 33.575C41.2917 33.8583 40.9333 34 40.5 34H16ZM7.5 17C7.06667 17 6.70833 16.8583 6.425 16.575C6.14167 16.2917 6 15.9333 6 15.5C6 15.0667 6.14167 14.7083 6.425 14.425C6.70833 14.1417 7.06667 14 7.5 14C7.93333 14 8.29167 14.1417 8.575 14.425C8.85833 14.7083 9 15.0667 9 15.5C9 15.9333 8.85833 16.2917 8.575 16.575C8.29167 16.8583 7.93333 17 7.5 17ZM7.5 25.5C7.06667 25.5 6.70833 25.3583 6.425 25.075C6.14167 24.7917 6 24.4333 6 24C6 23.5667 6.14167 23.2083 6.425 22.925C6.70833 22.6417 7.06667 22.5 7.5 22.5C7.93333 22.5 8.29167 22.6417 8.575 22.925C8.85833 23.2083 9 23.5667 9 24C9 24.4333 8.85833 24.7917 8.575 25.075C8.29167 25.3583 7.93333 25.5 7.5 25.5ZM7.5 34C7.06667 34 6.70833 33.8583 6.425 33.575C6.14167 33.2917 6 32.9333 6 32.5C6 32.0667 6.14167 31.7083 6.425 31.425C6.70833 31.1417 7.06667 31 7.5 31C7.93333 31 8.29167 31.1417 8.575 31.425C8.85833 31.7083 9 32.0667 9 32.5C9 32.9333 8.85833 33.2917 8.575 33.575C8.29167 33.8583 7.93333 34 7.5 34Z"
    />
  </svg>
</template>
