<template>
  <svg
    class="svg-icon"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00039 8.70002L4.50039 12.2C4.40039 12.3 4.28372 12.35 4.15039 12.35C4.01706 12.35 3.90039 12.3 3.80039 12.2C3.70039 12.1 3.65039 11.9834 3.65039 11.85C3.65039 11.7167 3.70039 11.6 3.80039 11.5L7.30039 8.00002L3.80039 4.50002C3.70039 4.40002 3.65039 4.28336 3.65039 4.15002C3.65039 4.01669 3.70039 3.90002 3.80039 3.80002C3.90039 3.70002 4.01706 3.65002 4.15039 3.65002C4.28372 3.65002 4.40039 3.70002 4.50039 3.80002L8.00039 7.30002L11.5004 3.80002C11.6004 3.70002 11.7171 3.65002 11.8504 3.65002C11.9837 3.65002 12.1004 3.70002 12.2004 3.80002C12.3004 3.90002 12.3504 4.01669 12.3504 4.15002C12.3504 4.28336 12.3004 4.40002 12.2004 4.50002L8.70039 8.00002L12.2004 11.5C12.3004 11.6 12.3504 11.7167 12.3504 11.85C12.3504 11.9834 12.3004 12.1 12.2004 12.2C12.1004 12.3 11.9837 12.35 11.8504 12.35C11.7171 12.35 11.6004 12.3 11.5004 12.2L8.00039 8.70002Z"
    />
  </svg>
</template>
