<script>
import "swiper/css";
import Swiper, { Navigation } from "swiper";

export default {
  mounted() {
    this.swiper = new Swiper(this.$refs.swiper, {
      modules: [Navigation],
      slidesPerView: 1,
      spaceBetween: 0,
      loop: false,
      navigation: {
        nextEl: this.$refs.next,
        prevEl: this.$refs.prev,
      },
      breakpoints: {
        1024: {
          slidesPerView: 3,
        },
      },
    });
  },
  methods: {
    prev() {
      this.swiper.slidePrev(this.settings.transition);
    },
    next() {
      this.swiper.slideNext(this.settings.transition);
    },
  },
};
</script>

<template>
  <div class="swiper page-top-line" ref="swiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide item">
        <div class="item-inner">
          <icon-delivery></icon-delivery>{{ $s("STR_BLACK_BANNER_1") }}
        </div>
      </div>
      <div class="swiper-slide item">
        <div class="item-inner">
          <icon-waranty></icon-waranty>{{ $s("STR_BLACK_BANNER_2") }}
        </div>
      </div>
      <div class="swiper-slide item">
        <div class="item-inner">
          <icon-secure></icon-secure>{{ $s("STR_BLACK_BANNER_3") }}
        </div>
      </div>
    </div>
    <button type="button" class="button before-button" ref="prev">
      <icon-arrow-prev></icon-arrow-prev>
    </button>
    <button type="button" class="button after-button" ref="next">
      <icon-arrow-next></icon-arrow-next>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.page-top-line {
  position: relative;
  margin-left: 0;
  margin-right: 0;
  padding-left: var(--page-margin);
  padding-right: var(--page-margin);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #fff;
  background: none;
  border: none;
  padding: 0;
  top: 17px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  z-index: 5;

  &.swiper-button-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.swiper-button-lock {
    display: none;
  }

  .svg-icon {
    width: 16px;
    height: 16px;
  }

  &.before-button {
    left: var(--page-margin);
  }

  &.after-button {
    right: var(--page-margin);
  }
}

.item-inner {
  justify-content: flex-start;
}

.item.swiper-slide-active .item-inner {
  flex-grow: 1;
  justify-content: flex-start;
}

.item.swiper-slide-active + .item .item-inner {
  flex-grow: 0;
  justify-content: flex-start;
}

.item.swiper-slide-active + .item + .item .item-inner,
.item.swiper-slide-active + .item + .item + .item .item-inner {
  flex-grow: 1;
  justify-content: flex-end;
}

.item-inner {
  //transition: flex-grow 0.5s;
  flex-grow: 1;
  justify-content: flex-start;
}
</style>

<style lang="scss"></style>
