<template>
  <svg
    class="svg-icon"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.6875 15.8438H20.3438C20.5938 15.8438 20.8073 15.75 20.9844 15.5625C21.1615 15.375 21.25 15.1562 21.25 14.9062C21.25 14.6354 21.1615 14.4115 20.9844 14.2344C20.8073 14.0573 20.5833 13.9688 20.3125 13.9688H9.65625C9.40625 13.9688 9.19271 14.0625 9.01562 14.25C8.83854 14.4375 8.75 14.6562 8.75 14.9062C8.75 15.1771 8.83854 15.401 9.01562 15.5781C9.19271 15.7552 9.41667 15.8438 9.6875 15.8438ZM15 27.5C13.2292 27.5 11.5833 27.1823 10.0625 26.5469C8.54167 25.9115 7.21875 25.0312 6.09375 23.9062C4.96875 22.7812 4.08854 21.4583 3.45313 19.9375C2.81771 18.4167 2.5 16.7708 2.5 15C2.5 13.25 2.81771 11.6146 3.45313 10.0938C4.08854 8.57292 4.96875 7.25 6.09375 6.125C7.21875 5 8.54167 4.11458 10.0625 3.46875C11.5833 2.82292 13.2292 2.5 15 2.5C16.75 2.5 18.3854 2.82292 19.9062 3.46875C21.4271 4.11458 22.75 5 23.875 6.125C25 7.25 25.8854 8.57292 26.5312 10.0938C27.1771 11.6146 27.5 13.25 27.5 15C27.5 16.7708 27.1771 18.4167 26.5312 19.9375C25.8854 21.4583 25 22.7812 23.875 23.9062C22.75 25.0312 21.4271 25.9115 19.9062 26.5469C18.3854 27.1823 16.75 27.5 15 27.5ZM15 25.625C18.0208 25.625 20.5469 24.6094 22.5781 22.5781C24.6094 20.5469 25.625 18.0208 25.625 15C25.625 11.9792 24.6094 9.45312 22.5781 7.42188C20.5469 5.39062 18.0208 4.375 15 4.375C11.9792 4.375 9.45312 5.39062 7.42188 7.42188C5.39062 9.45312 4.375 11.9792 4.375 15C4.375 18.0208 5.39062 20.5469 7.42188 22.5781C9.45312 24.6094 11.9792 25.625 15 25.625Z"
    />
  </svg>
</template>
