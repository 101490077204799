<script>
import Inputmask from "inputmask";

export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    upperCase: {
      type: Boolean,
      default: false,
    },
    lowerCase: {
      type: Boolean,
      default: false,
    },
    numbers: {
      type: Boolean,
      default: false,
    },
    alphaNumbers: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
    },
    delimiter: {
      type: String,
    },
    blocks: {
      type: Array,
      default: null,
    },
    mask: {
      type: String,
    },
    error: {
      type: Boolean,
      default: false,
    },
    asterisk: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFocused: false,
      valueData: this.modelValue,
      hiddenDefault: null,
      forceLift: false,
    };
  },
  watch: {
    modelValue(value) {
      this.valueData = value || "";
    },
    valueData(newValue) {
      let value = newValue || "";

      if (this.upperCase) {
        value = value.toUpperCase();
      } else if (this.lowerCase) {
        value = value.toLowerCase();
      } else if (this.numbers) {
        value = value.replace(/\D/g, "");
      }

      if (this.alphaNumbers) {
        value = value.replace(/[^0-9a-zA-Z]/g, "");
      }

      if (this.maxLength) {
        value = value.substr(0, this.maxLength);
      }

      if (value !== newValue) {
        this.valueData = value;
      }

      this.$emit("update:modelValue", value);
    },
  },
  computed: {
    isLabelLifted() {
      return (
        this.isFocused || this.modelValue || this.valueData || this.forceLift
      );
    },
  },
  mounted() {
    if (this.mask) {
      setTimeout(() => {
        let inputmask;
        inputmask = Inputmask({
          mask: this.mask,
          showMaskOnHover: false,
          showMaskOnFocus: true,
          clearMaskOnLostFocus: true,
          onBeforeMask: (value) => {
            const formatted = Inputmask.format(value, { mask: this.mask });
            const empty = this.mask.split("9").join("_");
            //let invalidValue = false;

            if (empty == formatted && value) {
              this.valueData = "";
            } else {
              this.valueData = formatted;
            }

            this.$emit("input", "" + this.valueData);

            setTimeout(() => {
              if (empty == formatted && value) {
                inputmask.setValue("");
              }

              const selectionStart = this.$refs.input.selectionStart;
              const firstEmpty = formatted.indexOf("_");

              if (firstEmpty < selectionStart) {
                this.$refs.input.setSelectionRange(firstEmpty, firstEmpty);
              }
            }, 16);

            return formatted;
          },
          onBeforePaste: (pastedValue) => {
            const formatted = Inputmask.format(pastedValue, {
              mask: this.mask,
            });
            const empty = this.mask.split("9").join("_");
            this.valueData = formatted;

            if (empty == formatted && pastedValue) {
              this.valueData = "";
            } else {
              this.valueData = formatted;
            }

            this.$emit("input", "" + this.valueData);

            setTimeout(() => {
              if (empty == formatted && pastedValue) {
                inputmask.setValue("" + this.valueData);
              }

              const selectionStart = this.$refs.input.selectionStart;
              const firstEmpty = formatted.indexOf("_");

              if (firstEmpty < selectionStart) {
                this.$refs.input.setSelectionRange(firstEmpty, firstEmpty);
              }
            }, 10);

            return formatted;
          },
        }).mask(this.$refs.input);
      }, 500);
    }

    if (this.hidden) {
      this.hiddenDefault = this.value;
    }

    const checkAutofill = () => {
      try {
        if (this.$el.querySelector("input:-webkit-autofill")) {
          this.forceLift = true;
        }

        if (this.$el.querySelector("input:autofill")) {
          this.forceLift = true;
        }
      } catch (error) {
        //
      }
    };

    setTimeout(() => {
      checkAutofill();
    }, 50);

    setTimeout(() => {
      checkAutofill();
    }, 250);

    setTimeout(() => {
      checkAutofill();
    }, 500);

    setTimeout(() => {
      checkAutofill();
    }, 1000);

    setTimeout(() => {
      checkAutofill();
    }, 1500);
  },
  methods: {
    focus() {
      this.$refs.input.focus();
      this.onFocus();
    },
    scrollToThis() {
      this.$el.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });

      this.focus();
    },
    onFocus() {
      this.isFocused = true;

      if (this.hiddenDefault == this.valueData) {
        this.valueData = "";
      }
    },
    onBlur() {
      this.isFocused = false;

      if (this.valueData == "") {
        this.valueData = this.hiddenDefault;
      }
    },
    onInput() {
      this.forceLift = false;
      /*
            if (this.mask && event.data) {
                const formatted = Inputmask.format(event.data, { mask: this.mask });
                this.valueData = formatted;
                this.$emit("input", formatted);
            }
            */
    },
    onChange() {
      this.forceLift = false;
    },
  },
};
</script>

<template>
  <label
    class="text-field-wrap"
    :class="{
      'is-focused': isFocused,
      'is-label-lifted': isLabelLifted,
      'is-loading': loading,
      error: error,
    }"
  >
    <slot name="prepend" />
    <div class="content">
      <div v-if="label" class="label">
        <span class="label-text">{{ label }}</span
        ><span v-if="asterisk" class="asterisk">*</span>
      </div>
      <input
        v-if="blocks"
        ref="input"
        class="input"
        v-bind="$attrs"
        @focus="onFocus"
        @blur="onBlur"
        @input="onInput"
        @change="onChange"
      />
      <input
        v-else
        ref="input"
        class="input"
        v-model="valueData"
        v-bind="$attrs"
        @focus="onFocus"
        @blur="onBlur"
        @input="onInput"
        @change="onChange"
      />
    </div>
    <slot name="append" />
  </label>
</template>

<style lang="scss" scoped>
.text-field-wrap {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: flex-start;
  background: #fff;
  border: 1px solid $color-border;
  border-radius: 0;
  height: 48px;
  box-shadow: 0 0 0 2px transparent;
  transition: box-shadow 0.3s, border-color 0.3s;

  &.is-loading::after {
    content: "";
    position: absolute;
    right: 22px;
    top: 50%;
    width: 24px;
    height: 24px;
    margin: -12px;
    box-sizing: border-box;
    border: 3px solid #000;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-radius: 12px;
    animation: loading-spin 1s infinite linear;
  }

  /*
  &.is-focused {
      box-shadow: 0 0 0 2px $color-green;
  }
  */

  &.error {
    border-color: $color-alert;
  }

  .content {
    flex-grow: 1;
    flex-shrink: 1;
    position: relative;
  }

  .label {
    left: 10px;
    right: 10px;
    top: 13px;
    position: absolute;
    z-index: 1;
    font-size: 16px;
    line-height: 1.3;
    color: #000;
    transition: font-size 0.3s, top 0.3s, color 0.3s;
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
    justify-content: flex-start;

    .label-text {
      flex-grow: 0;
      flex-shrink: 1;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .asterisk {
      flex-grow: 0;
      flex-shrink: 0;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      margin-left: 5px;
      margin-right: 2px;
    }
  }

  &.is-label-lifted .label {
    top: 5px;
    font-size: 12px;
    color: $color-text-grey;
  }

  .input {
    width: 100%;
    height: 48px;
    font-size: 16px;
    line-height: 1.3;
    padding: 17px 10px 0 10px;
    border: none;
    box-sizing: border-box;
    color: #000;
    background: #fff;
    box-shadow: inset 0 0 0 50px #fff;
    border-radius: 0;
    transition: 5000s background-color;
    outline: none !important;

    &:autofill {
      font-size: 18px !important;
      line-height: 1.3 !important;
      color: #000 !important;
    }

    &:-webkit-autofill {
      font-size: 18px !important;
      line-height: 1.3 !important;
      color: #000 !important;
    }
  }
}
</style>
