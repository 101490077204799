<template>
  <svg
    class="svg-icon"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9999 18.8438C14.8749 18.8438 14.7604 18.8229 14.6562 18.7813C14.552 18.7396 14.4479 18.6667 14.3437 18.5625L8.15619 12.375C7.98953 12.2083 7.9114 11.9844 7.92182 11.7031C7.93223 11.4219 8.02078 11.1979 8.18744 11.0313C8.39578 10.8229 8.61973 10.7344 8.85932 10.7656C9.0989 10.7969 9.31244 10.8958 9.49994 11.0625L14.9999 16.5625L20.4999 11.0625C20.6666 10.8958 20.8906 10.8021 21.1718 10.7813C21.4531 10.7604 21.677 10.8542 21.8437 11.0625C22.052 11.2292 22.1406 11.4479 22.1093 11.7188C22.0781 11.9896 21.9791 12.2188 21.8124 12.4063L15.6562 18.5625C15.552 18.6667 15.4479 18.7396 15.3437 18.7813C15.2395 18.8229 15.1249 18.8438 14.9999 18.8438Z"
    />
  </svg>
</template>
