import Vuex from "vuex";
import findProductInCart from "@/util/findProductInCart.js";
import ajax from "@/util/ajax.js";

const store = new Vuex.Store({
  state: {
    cartReady: false,
    favorites: [],
    cart: [],

    promocodeFound: null,
    productsQty: 0,
    subtotal: 0,
    discount: 0,
    discountPromocode: 0,
    deliveryCost: 0,
    total: 0,

    timestamp: 0,
  },
  getters: {},
  mutations: {
    setCartReady(state, value) {
      state.cartReady = value;
    },
    setStateData(state, data) {
      state.promocodeFound =
        data.promocodeFound !== undefined
          ? data.promocodeFound
          : state.promocodeFound;
      state.favorites =
        data.favorites !== undefined ? data.favorites : state.favorites;
      state.cart = data.cart !== undefined ? data.cart : state.cart;
      state.productsQty =
        data.productsQty !== undefined ? data.productsQty : state.productsQty;
      state.subtotal =
        data.subtotal !== undefined ? data.subtotal : state.subtotal;
      state.discount =
        data.discount !== undefined ? data.discount : state.discount;
      state.discountPromocode =
        data.discountPromocode !== undefined
          ? data.discountPromocode
          : state.discountPromocode;
      state.deliveryCost =
        data.deliveryCost !== undefined
          ? data.deliveryCost
          : state.deliveryCost;
      state.total = data.total !== undefined ? data.total : state.total;
      state.timestamp =
        data.timestamp !== undefined ? data.timestamp : state.timestamp;
    },
    addProductToCart(state, productData) {
      const inCart = findProductInCart(
        state.cart,
        productData.productId,
        productData.colorId
      );
      if (inCart !== null) {
        state.cart[inCart].quantity++;
        state.cart[inCart].amount =
          state.cart[inCart].price * state.cart[inCart].quantity;

        if (state.cart[inCart].priceOld) {
          state.cart[inCart].amountOld =
            state.cart[inCart].priceOld * state.cart[inCart].quantity;
        } else {
          state.cart[inCart].amountOld = 0;
        }
      } else {
        productData.quantity = 1;
        productData.amount = productData.price;

        if (productData.priceOld) {
          productData.amountOld = productData.priceOld;
        } else {
          productData.amountOld = 0;
        }

        state.cart.push(productData);
      }

      state.timestamp = Date.now();
    },
    updateProductCartEntry(state, productData) {
      const inCart = findProductInCart(
        state.cart,
        productData.productId,
        productData.colorId
      );

      if (inCart !== null) {
        Object.assign(state.cart[inCart], productData);
        state.cart[inCart].amount =
          state.cart[inCart].price * state.cart[inCart].quantity;

        if (state.cart[inCart].priceOld) {
          state.cart[inCart].amountOld =
            state.cart[inCart].priceOld * state.cart[inCart].quantity;
        } else {
          state.cart[inCart].amountOld = 0;
        }

        state.timestamp = Date.now();
      }
    },
    changeCartItem(state, data) {
      const index = data.index;
      const productData = data.productData;

      productData.quantity = 1;
      productData.amount = productData.price;

      if (productData.priceOld) {
        productData.amountOld = productData.priceOld;
      } else {
        productData.amountOld = 0;
      }

      state.cart[index] = productData;
      state.timestamp = Date.now();
    },
    removeProductFromCart(state, productIndex) {
      state.cart.splice(productIndex, 1);
      state.timestamp = Date.now();
    },
    clearCart(state) {
      state.cart = [];
      state.timestamp = Date.now();
    },
    addProductToFavorites(state, productData) {
      state.favorites.push(productData);
      state.timestamp = Date.now();
    },
    removeProductFromFavorites(state, productIndex) {
      state.favorites.splice(productIndex, 1);
      state.timestamp = Date.now();
    },
  },
  actions: {
    async addProductToCart(context, productData) {
      context.commit("addProductToCart", productData);
      store.dispatch("updateServerCart");
    },
    updateProductCartEntry(context, productData) {
      context.commit("updateProductCartEntry", productData);
      store.dispatch("save");
    },
    async changeCartItem(context, data) {
      context.commit("changeCartItem", data);
      store.dispatch("updateServerCart");
    },
    async updateServerCart(context, data) {
      const safeData = data || {};
      const productCartData = await ajax("/ajax/cart/get_cart_data.php", {
        uuid: safeData.uuid || null,
        promocode: safeData.promocode || null,
        fields: safeData.fields || null,
        getDelivery: safeData.getDelivery || null,
        productsList: context.state.cart,
        final: safeData.final || null,
        otpToken: safeData.otpToken || null,
      });

      if (productCartData.products) {
        for (const product of productCartData.products) {
          context.commit("updateProductCartEntry", product);
        }

        context.commit("setStateData", {
          promocodeFound: productCartData.promocodeFound,
          productsQty: productCartData.productsQty,
          subtotal: productCartData.subtotal,
          discount: productCartData.discount,
          discountPromocode: productCartData.discountPromocode,
          deliveryCost: productCartData.deliveryCost,
          total: productCartData.total,
        });
      }

      store.dispatch("save");

      return productCartData;
    },
    removeProductFromCart(context, productIndex) {
      context.commit("removeProductFromCart", productIndex);
      store.dispatch("save");
    },
    addProductToFavorites(context, productData) {
      context.commit("addProductToFavorites", productData);
      store.dispatch("save");
    },
    removeProductFromFavorites(context, productIndex) {
      context.commit("removeProductFromFavorites", productIndex);
      store.dispatch("save");
    },
    async load(context) {
      const data = JSON.parse(window.localStorage.getItem("state"));
      if (
        data &&
        data.timestamp > 0 &&
        data.timestamp > context.state.timestamp
      ) {
        context.commit("setCartReady", false);
        context.commit("setStateData", data);
        store.dispatch("updateServerCart");
        context.commit("setCartReady", true);
      }
    },
    save(context) {
      window.localStorage.setItem(
        "state",
        JSON.stringify({
          favorites: context.state.favorites,
          cart: context.state.cart,
          timestamp: context.state.timestamp,
        })
      );
    },
  },
});

export default store;
