<template>
  <svg
    class="svg-icon"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0938 21.25C15.3646 21.25 15.5885 21.1615 15.7656 20.9844C15.9427 20.8073 16.0312 20.5833 16.0312 20.3125V16.0625H20.3438C20.5938 16.0625 20.8073 15.974 20.9844 15.7969C21.1615 15.6198 21.25 15.3958 21.25 15.125C21.25 14.8542 21.1615 14.6302 20.9844 14.4531C20.8073 14.276 20.5833 14.1875 20.3125 14.1875H16.0312V9.65625C16.0312 9.40625 15.9427 9.19271 15.7656 9.01562C15.5885 8.83854 15.3646 8.75 15.0938 8.75C14.8229 8.75 14.599 8.83854 14.4219 9.01562C14.2448 9.19271 14.1562 9.41667 14.1562 9.6875V14.1875H9.65625C9.40625 14.1875 9.19271 14.276 9.01562 14.4531C8.83854 14.6302 8.75 14.8542 8.75 15.125C8.75 15.3958 8.83854 15.6198 9.01562 15.7969C9.19271 15.974 9.41667 16.0625 9.6875 16.0625H14.1562V20.3438C14.1562 20.5938 14.2448 20.8073 14.4219 20.9844C14.599 21.1615 14.8229 21.25 15.0938 21.25ZM15 27.5C13.2292 27.5 11.5833 27.1823 10.0625 26.5469C8.54167 25.9115 7.21875 25.0312 6.09375 23.9062C4.96875 22.7812 4.08854 21.4583 3.45313 19.9375C2.81771 18.4167 2.5 16.7708 2.5 15C2.5 13.25 2.81771 11.6146 3.45313 10.0938C4.08854 8.57292 4.96875 7.25 6.09375 6.125C7.21875 5 8.54167 4.11458 10.0625 3.46875C11.5833 2.82292 13.2292 2.5 15 2.5C16.75 2.5 18.3854 2.82292 19.9062 3.46875C21.4271 4.11458 22.75 5 23.875 6.125C25 7.25 25.8854 8.57292 26.5312 10.0938C27.1771 11.6146 27.5 13.25 27.5 15C27.5 16.7708 27.1771 18.4167 26.5312 19.9375C25.8854 21.4583 25 22.7812 23.875 23.9062C22.75 25.0312 21.4271 25.9115 19.9062 26.5469C18.3854 27.1823 16.75 27.5 15 27.5ZM15 25.625C17.9167 25.625 20.4167 24.5833 22.5 22.5C24.5833 20.4167 25.625 17.9167 25.625 15C25.625 12.0833 24.5833 9.58333 22.5 7.5C20.4167 5.41667 17.9167 4.375 15 4.375C12.0833 4.375 9.58333 5.41667 7.5 7.5C5.41667 9.58333 4.375 12.0833 4.375 15C4.375 17.9167 5.41667 20.4167 7.5 22.5C9.58333 24.5833 12.0833 25.625 15 25.625Z"
    />
  </svg>
</template>
