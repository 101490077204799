<template>
  <svg
    class="svg-icon"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 23.9502C21.8 23.9502 20 23.2502 18.6 21.8502C17.2 20.4502 16.5 18.6502 16.5 16.4502C16.5 14.2502 17.2 12.4502 18.6 11.0502C20 9.6502 21.8 8.9502 24 8.9502C26.2 8.9502 28 9.6502 29.4 11.0502C30.8 12.4502 31.5 14.2502 31.5 16.4502C31.5 18.6502 30.8 20.4502 29.4 21.8502C28 23.2502 26.2 23.9502 24 23.9502ZM37 40.0002H11C10.1667 40.0002 9.45833 39.7085 8.875 39.1252C8.29167 38.5419 8 37.8335 8 37.0002V35.3002C8 34.0335 8.31667 32.9502 8.95 32.0502C9.58333 31.1502 10.4 30.4669 11.4 30.0002C13.6333 29.0002 15.775 28.2502 17.825 27.7502C19.875 27.2502 21.9333 27.0002 24 27.0002C26.0667 27.0002 28.1167 27.2585 30.15 27.7752C32.1833 28.2919 34.3167 29.0335 36.55 30.0002C37.5833 30.4669 38.4167 31.1502 39.05 32.0502C39.6833 32.9502 40 34.0335 40 35.3002V37.0002C40 37.8335 39.7083 38.5419 39.125 39.1252C38.5417 39.7085 37.8333 40.0002 37 40.0002ZM11 37.0002H37V35.3002C37 34.7669 36.8417 34.2585 36.525 33.7752C36.2083 33.2919 35.8167 32.9335 35.35 32.7002C33.2167 31.6669 31.2667 30.9585 29.5 30.5752C27.7333 30.1919 25.9 30.0002 24 30.0002C22.1 30.0002 20.25 30.1919 18.45 30.5752C16.65 30.9585 14.7 31.6669 12.6 32.7002C12.1333 32.9335 11.75 33.2919 11.45 33.7752C11.15 34.2585 11 34.7669 11 35.3002V37.0002ZM24 20.9502C25.3 20.9502 26.375 20.5252 27.225 19.6752C28.075 18.8252 28.5 17.7502 28.5 16.4502C28.5 15.1502 28.075 14.0752 27.225 13.2252C26.375 12.3752 25.3 11.9502 24 11.9502C22.7 11.9502 21.625 12.3752 20.775 13.2252C19.925 14.0752 19.5 15.1502 19.5 16.4502C19.5 17.7502 19.925 18.8252 20.775 19.6752C21.625 20.5252 22.7 20.9502 24 20.9502Z"
    />
  </svg>
</template>
