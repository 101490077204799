<template>
  <svg
    class="svg-icon"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 23.5C24.9667 23.5 25.7917 23.1583 26.475 22.475C27.1583 21.7917 27.5 20.9667 27.5 20C27.5 19.0333 27.1583 18.2083 26.475 17.525C25.7917 16.8417 24.9667 16.5 24 16.5C23.0333 16.5 22.2083 16.8417 21.525 17.525C20.8417 18.2083 20.5 19.0333 20.5 20C20.5 20.9667 20.8417 21.7917 21.525 22.475C22.2083 23.1583 23.0333 23.5 24 23.5ZM24 40.05C28.4333 36.0167 31.7083 32.3583 33.825 29.075C35.9417 25.7917 37 22.9 37 20.4C37 16.4667 35.7417 13.25 33.225 10.75C30.7083 8.25 27.6333 7 24 7C20.3667 7 17.2917 8.25 14.775 10.75C12.2583 13.25 11 16.4667 11 20.4C11 22.9 12.0833 25.7917 14.25 29.075C16.4167 32.3583 19.6667 36.0167 24 40.05ZM24.05 43.4C23.8167 43.4 23.6 43.3667 23.4 43.3C23.2 43.2333 23.0167 43.1333 22.85 43C17.8833 38.6333 14.1667 34.5833 11.7 30.85C9.23333 27.1167 8 23.6333 8 20.4C8 15.4 9.60833 11.4167 12.825 8.45C16.0417 5.48333 19.7667 4 24 4C28.2333 4 31.9583 5.48333 35.175 8.45C38.3917 11.4167 40 15.4 40 20.4C40 23.6333 38.7667 27.1167 36.3 30.85C33.8333 34.5833 30.1167 38.6333 25.15 43C24.9833 43.1333 24.8083 43.2333 24.625 43.3C24.4417 43.3667 24.25 43.4 24.05 43.4Z"
    />
  </svg>
</template>
