<script>
export default {
  name: "TelLink",
  computed: {
    link() {
      const tel = (this.$slots.default()[0].children || "").replace(
        /[^0-9+]/g,
        ""
      );
      return "tel:" + tel;
    },
  },
};
</script>

<template>
  <a class="tel-link" v-bind:href="link"><slot /></a>
</template>
