<script>
import "swiper/css";
import Swiper, { FreeMode, Scrollbar } from "swiper";

export default {
  mounted() {
    this.swiper = new Swiper(this.$refs.swiper, {
      modules: [FreeMode, Scrollbar],
      slidesPerView: "auto",
      spaceBetween: 20,
      threshold: 0,
      loop: false,
      freeMode: {
        enabled: true,
        sticky: false,
      },
      scrollbar: {
        el: this.$refs.scrollbar,
        draggable: true,
      },
    });
  },
};
</script>

<template>
  <div class="swiper product-scroll" ref="swiper">
    <div class="swiper-wrapper">
      <div class="swiper-slide ending"></div>
      <slot></slot>
      <div class="swiper-slide ending"></div>
    </div>
    <div class="swiper-scrollbar" ref="scrollbar"></div>
  </div>
</template>

<style lang="scss" scoped>
.swiper {
  width: 100%;
  margin-top: -10px;
}

.swiper-wrapper {
  padding: 10px 0 50px 0;
}

.ending {
  width: calc(var(--page-margin) - 20px);
}
</style>

<style lang="scss">
.product-scroll .product-tile {
  width: 420px;
}

.product-scroll .swiper-scrollbar {
  position: relative;
  height: 13px;
  width: calc(100% - var(--page-margin) - var(--page-margin));
  max-width: 800px;
  margin: 25px auto 0 auto;

  &::before {
    content: "";
    display: block;
    position: absolute;
    height: 3px;
    top: 5px;
    width: 100%;
    background: #ededed;
  }
}

.product-scroll .swiper-scrollbar-drag {
  position: relative;
  height: 13px;
  max-width: 450px;
  cursor: ew-resize;

  &::before {
    content: "";
    display: block;
    position: absolute;
    height: 3px;
    top: 5px;
    width: 100%;
    background: #000;
  }
}
</style>
