<template>
  <svg
    class="svg-icon"
    width="12"
    height="20"
    viewBox="0 0 12 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.554387 10.1054C0.554387 9.93869 0.582165 9.78591 0.637721 9.64702C0.693276 9.50813 0.790497 9.36924 0.929386 9.23035L9.17939 0.980351C9.40161 0.758129 9.70022 0.653963 10.0752 0.667851C10.4502 0.68174 10.7488 0.799796 10.9711 1.02202C11.2488 1.2998 11.3669 1.59841 11.3252 1.91785C11.2836 2.2373 11.1516 2.52202 10.9294 2.77202L3.59605 10.1054L10.9294 17.4387C11.1516 17.6609 11.2766 17.9595 11.3044 18.3345C11.3322 18.7095 11.2072 19.0081 10.9294 19.2304C10.7072 19.5081 10.4155 19.6262 10.0544 19.5845C9.69328 19.5429 9.38772 19.4109 9.13772 19.1887L0.929386 10.9804C0.790497 10.8415 0.693276 10.7026 0.637721 10.5637C0.582165 10.4248 0.554387 10.272 0.554387 10.1054V10.1054Z"
    />
  </svg>
</template>
