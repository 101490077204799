<script>
export default {
  name: "FaqItem",
  props: {
    item: Object,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    height() {
      if (this.isOpen) {
        return this.$refs.wrapper.offsetHeight + "px";
      } else {
        return "60px";
      }
    },
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        const faqOpen = new CustomEvent("faqopen", {
          detail: {
            faqId: this.item.id,
          },
        });

        window.dispatchEvent(faqOpen);
      }
    },
    hashUpdate() {
      if (
        window.location.hash &&
        window.location.hash.startsWith("#question-")
      ) {
        const openId = parseInt(window.location.hash.substring(10));
        if (parseInt(this.item.id) == openId) {
          this.isOpen = true;
        } else {
          this.isOpen = false;
        }

        this.$nextTick(() => {
          const anchorTop = document.getElementById(
            "question-anchor-" + openId
          ).offsetTop;
          window.scrollTo({ top: anchorTop, behavior: "smooth" });
        });
      }
    },
  },
  mounted() {
    window.addEventListener("faqopen", (event) => {
      if (event.detail.faqId != this.item.id) {
        this.isOpen = false;
      }
    });

    addEventListener("hashchange", () => {
      this.hashUpdate();
    });

    this.$nextTick(() => {
      this.hashUpdate();
    });
  },
};
</script>

<template>
  <div class="faq-item" :style="{ height: height }">
    <div ref="wrapper" @click="toggle">
      <div class="faq-question">
        {{ item.question }}
        <icon-toggle-arrow :class="{ open: isOpen }"></icon-toggle-arrow>
      </div>
      <article class="faq-answer" v-html="item.answer"></article>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.faq-item {
  overflow: hidden;
  transition: height 0.3s;
  cursor: pointer;
  display: block;
  border-bottom: 1px solid $color-border;
}

.faq-question {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  align-items: left;
  height: 60px;
  padding-right: 50px;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;

  .svg-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    margin: -15px;
    transition: transform 0.3s;

    &.open {
      transform: rotate(180deg);
    }
  }
}

.faq-answer {
  padding-bottom: 20px;
}
</style>
